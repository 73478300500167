<template>
  <div class="top-box" v-if="ticketId">
    <span class="inline"></span>
    <span class="text">操作票-编辑</span>
  </div>
  <a-alert
    v-if="ticketId"
    message="该页面操作不是实时保存，修改数据后记得点击保存按钮保存数据！"
    banner
    closable
  />
  <a-form
    class="myForm box-shadow"
    :label-col="{ span: 3 }"
    :wrapper-col="{ span: 20 }"
    :model="formState"
    :rules="rules"
    ref="formRef"
  >
    <a-form-item label="操作票名称:" name="ticket">
      <a-input
        placeholder="请输入操作票名称"
        :maxlength="100"
        autocomplete="off"
        v-model:value="formState.ticket"
      />
    </a-form-item>
    <a-form-item label="票类型：">
      <a-radio-group
        name="radioGroup"
        v-model:value="formState.model"
        :disabled="ticketId"
      >
        <!-- <a-radio :value="0">普通票</a-radio> -->
        <a-radio :value="1">模板票</a-radio>
        <a-radio :value="2">大小项卡</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item
      label="操作票编号:"
      name="ticket_num"
      v-if="formState.model == 0"
    >
      <a-input
        placeholder="请输入操作票编号"
        :maxlength="20"
        autocomplete="off"
        v-model:value="formState.ticket_num"
      />
    </a-form-item>
    <a-form-item label="操作票编号:" v-else>
      <a-input
        placeholder="请输入操作票编号"
        :maxlength="20"
        autocomplete="off"
        v-model:value="formState.ticket_num"
      />
    </a-form-item>
    <a-form-item label="操作任务:" name="content">
      <a-input
        placeholder="请输入操作任务"
        :maxlength="100"
        autocomplete="off"
        v-model:value="formState.content"
      />
    </a-form-item>
    <a-form-item label="操作项:">
      <operation-list-parent
        ref="operationRef"
        :data="formState.items"
      ></operation-list-parent>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 3, offset: 3 }" v-if="!disabled">
      <a-button
        type="primary"
        htmlType="submit"
        @click.prevent="handleCreateTicket"
      >
        提交
      </a-button>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 3, offset: 3 }" v-else>
      <a-button
        type="primary"
        htmlType="submit"
        @click.prevent="handleEditTicket"
      >
        保存
      </a-button>
    </a-form-item>
  </a-form>
  <!-- 工序卡弹出层 -->
  <processModel
    v-model:value="isShowProcessModel"
    @select="handleSelect"
    :id="nowNodeId"
  ></processModel>
</template>

<script>
import {
  defineComponent,
  reactive,
  computed,
  ref,
  onMounted,
  provide
} from 'vue'
import operationListParent from '@/views/ticketManger/components/operationListParent.vue'
import processModel from '@/views/ticketManger/components/processModel.vue'
import { createTicket, getTicketDes, editTicket } from '@/api'
import { message } from 'ant-design-vue'
import { filterKeys } from '@/hooks/common-hooks'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'EditTicket',
  props: { id: [String, Number] },
  components: { operationListParent, processModel },
  emits: ['changeIndex'],
  setup(props, ctx) {
    const router = useRouter()
    const isShowProcessModel = ref(false)
    const formRef = ref()
    const operationRef = ref()
    const formState = reactive({
      ticket_num: '',
      ticket: '',
      content: '',
      items: [],
      model: 1
    })
    const ticketId = computed(() => props.id)
    const disabled = computed(() => {
      if (ticketId.value) {
        return true
      } else {
        return false
      }
    })
    const rules = {
      ticket_num: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ],
      ticket: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ],
      content: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ]
    }
    // 创建工序卡
    const handleCreateTicket = () => {
      formRef.value.validate().then(() => {
        if (!operationRef.value.handleCheckNode()) {
          return false
        }
        // if (operationRef.value.getList().length === 0) {
        //   message.error('请填写操作项')
        //   return false
        // }
        createTicket(
          Object.assign(formState, {
            items: operationRef.value.getList(),
            model: formState.model
          })
        ).then(() => {
          message.success('创建成功')
          ctx.emit('changeIndex', 0)
        })
      })
    }
    // 编辑页面时,初始化详情页
    const initData = () => {
      getTicketDes({
        id: props.id
      }).then(res => {
        filterKeys(formState, res.data)
        formState.model = res.data.model
      })
    }
    // 编辑提交数据
    const handleEditTicket = () => {
      formRef.value.validate().then(() => {
        if (
          operationRef.value.getList().length > 0 &&
          !operationRef.value.handleCheckNode()
        ) {
          return false
        }
        editTicket({
          ticket: formState.ticket,
          ticket_id: ticketId.value,
          ticket_num: formState.ticket_num,
          content: formState.content,
          items: operationRef.value.getList([
            'id',
            'content',
            'process_id',
            'items'
          ])
        }).then(() => {
          message.success('编辑成功')
          // router.replace({ name: 'TicketManger' })
        })
      })
    }
    let nowNode = ''
    const nowNodeId = ref(0)
    // 修改工序卡弹出层状态
    const changeModelStatus = (status, node) => {
      isShowProcessModel.value = status
      if (node) {
        nowNode = node
        nowNodeId.value = node.process_id
      }
    }
    provide('changeModelStatus', changeModelStatus)
    const handleSelect = id => {
      if (nowNode) {
        nowNode.process_id = id
        nowNodeId.value = id
      }
    }
    onMounted(() => {
      if (props.id) {
        initData()
      }
    })
    return {
      formRef,
      operationRef,
      formState,
      disabled,
      ticketId,
      rules,
      handleCreateTicket,
      handleEditTicket,
      isShowProcessModel,
      handleSelect,
      nowNodeId
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/scss/theme.scss';
@import '@/scss/form.scss';
</style>
