<template>
  <div class="list-box mb-5">
    <div class="flex flex-align-c mb-5">
      <!-- 加号 -->
      <PlusSquareOutlined
        v-show="node.level > 1"
        title="新增兄弟项"
        :style="{
          fontSize: '36px',
          color: '#eee',
          cursor: 'pointer'
        }"
        @click="handleAddBrotherItem(parentNode, node.level, parentKey)"
      />
      <!-- 减号 -->
      <MinusSquareOutlined
        v-show="node.level > 1"
        title="删除当前项"
        :style="{
          fontSize: '36px',
          color: '#eee',
          cursor: 'pointer'
        }"
        @click="handleDeleteItem(node, parentNode, parentKey)"
      />
      <a-input
        placeholder="请输入项"
        :maxlength="100"
        autocomplete="off"
        :value="node.content"
        @change="changeInputValue(node, $event)"
      />
      <!-- 添加工序卡 -->
      <a-button class="ml-5" @click="handleOpenModal(node)">
        添加工序卡
      </a-button>
      <!-- 预览工序卡 -->
      <a-badge
        :dot="node.process_id !== 0"
        :number-style="{
          backgroundColor: '#40a9ff',
          width: '8px',
          height: '8px'
        }"
      >
        <a-button class="ml-5" @click="handleView(node)"> 预览工序卡 </a-button>
      </a-badge>

      <!-- 加号 -->
      <PlusSquareOutlined
        title="新增子项"
        :style="{
          fontSize: '36px',
          color: '#eee',
          cursor: 'pointer',
          'margin-left': '3px'
        }"
        @click="handleAddSmallItem(node)"
      />
    </div>
    <template v-for="(item, index) in node.items" :key="index">
      <operation-list
        :max="max"
        :node="item"
        :parentNode="node.items"
        :parentKey="index"
        :style="{ 'padding-left': item.level + 10 + 'px' }"
      ></operation-list>
    </template>
  </div>
</template>

<script>
import { defineComponent, inject } from 'vue'
import { PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'operationList',
  props: {
    node: {},
    parentNode: {},
    parentKey: {},
    max: {}
  },
  components: {
    PlusSquareOutlined,
    MinusSquareOutlined
  },
  setup(props, ctx) {
    const updateList = inject('updateList')
    const changeModelStatus = inject('changeModelStatus')
    const handleCheckNode = inject('handleCheckNode')
    const router = useRouter()
    const _items = {
      content: '',
      process_id: 0
    }
    const handleAddBrotherItem = (parentNode, level, parentIndex) => {
      if (!handleCheckNode()) return
      parentNode.splice(
        parentIndex + 1,
        0,
        JSON.parse(JSON.stringify(Object.assign(_items, { level })))
      )
      updateList()
    }
    const handleAddSmallItem = node => {
      if (!handleCheckNode()) return
      if (node.level === props.max) {
        message.warning('已到最大子节点,不能再添加子节点')
        return
      }
      if (!Object.prototype.hasOwnProperty.call(node, 'items')) {
        node.items = [
          JSON.parse(
            JSON.stringify(Object.assign(_items, { level: node.level + 1 }))
          )
        ]
      } else {
        node.items.push(
          JSON.parse(
            JSON.stringify(Object.assign(_items, { level: node.level + 1 }))
          )
        )
      }
      updateList()
    }
    const changeInputValue = (node, e) => {
      node.content = e.target.value
    }
    const handleDeleteItem = (node, parentNode, keyIndex) => {
      parentNode.splice(keyIndex, 1)
      updateList()
    }
    // 打开工序卡弹出层
    const handleOpenModal = node => {
      changeModelStatus(true, node)
    }
    // 预览工序卡
    const handleView = node => {
      if (node.process_id === 0) {
        message.warning('请先选择工序卡')
        return false
      }
      const { href } = router.resolve({
        name: 'ProcessView',
        params: { id: node.process_id }
      })
      window.open(href, '_blank')
    }
    return {
      handleAddBrotherItem,
      handleAddSmallItem,
      changeInputValue,
      handleDeleteItem,
      handleOpenModal,
      handleView
    }
  }
})
</script>

<style lang="scss" scoped>
.list-box {
  .ant-input {
    border-color: #eee;
    &:focus {
      border-color: #40a9ff;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 2px 0px #1890ff;
    }
  }
}
</style>
