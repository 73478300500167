<template>
  <a-modal
    width="60%"
    :visible="value"
    title="选择工序卡"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="saveSubmit"
  >
    <div class="mb-20">
      <a-input-search
        :value="search"
        placeholder="搜索工序卡id、编号、名称、设备"
        @search="onSearch"
        @change="handleChangeInput"
      />
    </div>
    <div class="table-box">
      <a-table
        :columns="columns"
        :data-source="data"
        size="small"
        :pagination="pagination"
        rowKey="id"
        @change="changePage"
      >
        <template #action1="{ record }">
          <a-button type="link" size="small" @click="handleView(record.id)"
            >预览</a-button
          >
          <span class="ml-20">
            <a-checkbox
              :checked="record.id === id"
              @change="handleSelect(record.id, $event)"
            ></a-checkbox>
          </span>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, watch, inject } from 'vue'
import { getProcessList } from '@/api/index'
import { useRouter } from 'vue-router'
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 60
  },
  {
    title: '名称',
    dataIndex: 'process',
    key: 'process'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
const pagination = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  props: {
    value: {},
    id: {}
  },
  emits: ['select'],
  setup(props, ctx) {
    const changeModelStatus = inject('changeModelStatus')
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [],
      search: '',
      pagination
    })
    // 获取列表
    const fetchGetProcessList = () => {
      getProcessList({
        search: tableData.search,
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
      })
    }
    const changePage = pagination => {
      tableData.pagination.current = pagination.current
      fetchGetProcessList()
    }
    const handleCancel = () => {
      ctx.emit('update:value', false)
    }
    const saveSubmit = () => {
      changeModelStatus(false)
    }
    // 选择操作项
    const handleSelect = (id, e) => {
      if (!e.target.checked) {
        id = 0
      }
      ctx.emit('select', id)
    }
    // 预览工序卡
    const handleView = id => {
      const { href } = router.resolve({
        name: 'ProcessView',
        params: { id }
      })
      window.open(href, '_blank')
    }
    // 搜索
    const onSearch = () => {
      tableData.pagination.current = 1
      fetchGetProcessList()
    }
    const handleChangeInput = e => {
      tableData.search = e.target.value.trim()
    }
    watch(
      () => props.value,
      newValue => {
        if (newValue) {
          tableData.pagination.current = 1
          fetchGetProcessList()
        }
      }
    )
    return {
      handleCancel,
      saveSubmit,
      ...toRefs(tableData),
      changePage,
      handleSelect,
      handleView,
      onSearch,
      handleChangeInput
    }
  }
})
</script>

<style lang="scss" scoped>
.table-box {
  height: 520px;
  overflow-y: auto;
}
</style>
